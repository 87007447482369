import React, { useState, useEffect } from 'react';
import CollectionHeader from './components/CollectionHeader';
import PaoGeneCollection from './PaoGeneCollection';
import './App.css';

function App() {
  const [userAddress, setUserAddress] = useState('');
  const [showMetamaskInstallPrompt, setShowMetamaskInstallPrompt] = useState(false);

  const ethereum = window.ethereum; // Change this line

  useEffect(() => {
    if (ethereum) {
      ethereum.on('accountsChanged', (accounts) => {
        setUserAddress(accounts[0]);
      });
    } else {
      setShowMetamaskInstallPrompt(true);
    }
  }, [ethereum]);

  const connectToMetamask = async () => {
    if (ethereum) {
      try {
        const accounts = await ethereum.request({ method: 'eth_requestAccounts', params: [] });
        setUserAddress(accounts[0]);
      } catch (error) {
        console.error("User rejected the connection request");
      }
    } else {
      console.log("No Metamask (or other wallet) installed!");
      setShowMetamaskInstallPrompt(true);
    }
  };

  const redirectToMetamask = () => {
    window.location.href = "https://metamask.app.link/dapp/paozukan.nft-mint.xyz";
  };

  return (
    <div>
      <CollectionHeader userAddress={userAddress} onConnect={connectToMetamask} />
      <PaoGeneCollection userAddress={userAddress} />
      {showMetamaskInstallPrompt && (
        <div className="metamask-prompt">
          <p>No Metamask (or other wallet) installed!</p>
          <button onClick={redirectToMetamask}>Install MetaMask</button>
        </div>
      )}
    </div>
  );
}

export default App;
