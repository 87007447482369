import React from 'react';

function Card({ image, name, description, onClick }) {
  return (
    <div className="grid-item" onClick={onClick}>
      <img src={image} alt={name} />
      <div>{name}</div>
      <div>{description}</div>
    </div>
  );
}

export default Card;
