import React, { useState, useEffect } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import contractABI from './contractABI.json';
import Card from './components/Card';
import './App.css';

const contractAddress = '0xf53A70E7d2D22fF0DBf6E328C4B92F657bB306E8';

function PaoGeneCollection({ userAddress }) {
  const [tokens, setTokens] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null); // <-- ポップアップするトークンを管理するstate

  useEffect(() => {
    let isCancelled = false;

    const fetchTokens = async () => {
      if (userAddress) {
        try {
          const provider = new Web3Provider(window.ethereum);
          const contract = new Contract(contractAddress, contractABI, provider);
          const tokenIds = await contract.tokensOfOwner(userAddress);
          const tokenData = await Promise.all(
            tokenIds.map(async (id) => {
              const uri = await contract.tokenURI(id);
              const response = await fetch(uri);
              
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              
              const metadata = await response.json();
              return { id, ...metadata };
            })
          );

          if (!isCancelled) {
            setTokens(tokenData);
          }
        } catch (error) {
          console.error('There was a problem with your fetch operation: ', error);
        }
      }
    };

    fetchTokens();

    return () => {
      isCancelled = true;
    };
  }, [userAddress]);

  // カードをクリックしたときのハンドラ
  const handleCardClick = (token) => {
    setSelectedToken(token);
  };

  // ポップアップを閉じるハンドラ
  const handleClosePopup = () => {
    setSelectedToken(null);
  };

  return (
    <div className="grid-container">
      {tokens.map((token) => (
        <Card
          key={token.id}
          image={token.image}
          name={token.name}
          description={token.description}
          onClick={() => handleCardClick(token)} // <-- カードをクリックしたときのハンドラを設定
        />
      ))}

      {/* ポップアップの表示 */}
      {selectedToken && (
        <div className="popup" onClick={handleClosePopup}>
          <div className="popup-content">
            <img src={selectedToken.image} alt={`PaoGene ${selectedToken.id}`} />
            <div>{selectedToken.name}</div>
            <div>{selectedToken.description}</div>
          </div>
          <div className="close" onClick={handleClosePopup}>
            &times;
          </div>
        </div>
      )}
    </div>
  );
}

export default PaoGeneCollection;
